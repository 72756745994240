<template>
    <div>
        <div class="segment-control "
             :class="segmentControlClass"
             v-if="iconsByGroups.length>1"
        >
            <ul class="segment-control__list" :class="segmentControlListClass">
                <li class="segment-control__item item"
                    v-for="group in _mainList"
                    :key="'list-groups-'+group.id"
                    :class="{'item_active': group.id === activeGroupId}"
                    @click="activeGroupId = group.id"
                >
                    {{ group.name }}
                </li>

                <template v-if="moreList.length">
                    <li class="segment-control__item item"
                        :class="{'item_active': (moreItem.id === activeGroupId)}"
                        @click="activeGroupId = moreItem.id"
                    >
                        {{ (moreItem.name > '') ? moreItem.name : $t('text.No category') }}
                    </li>
                </template>

                <template v-if="moreList.length > 1">
                    <li class="segment-control__item item item_type_dropdown">
                        <button class="segment-control__button button_dropdown-menu"
                                @click.stop="showMenu = !showMenu"
                        >
                            More
                        </button>
                        <!-- /js/segment-control.js//Work Segment-control Button-dropdown-menu -->
                        <ul class="segment-control__dropdown-menu dropdown-menu position_right-top"
                            :class="{'dropdown-menu_open': showMenu}"
                        >
                            <li class="segment-control__item item"
                                v-for="group in moreList"
                                :key="'list-groups-'+group.id"
                                :class="{'item_active': group.id === activeGroupId}"
                                v-show="group.id !== moreItem.id"
                                @click="onClickMoreList(group)"
                            >
                                {{ (group.name > '') ? group.name : $t('text.No category') }}
                            </li>
                        </ul>
                    </li>
                </template>

            </ul>
        </div>
        <div class="card card_type_list-icons ptb__16 plr__12"
             :class="cardlistClass"
        >
            <ul class="list-icons">
                <template v-for="iconsGroup in iconsByGroups">
                    <li class="list-icons__item item"
                        v-for="(icon,i) in iconsGroup.icons"
                        :key="'list-icons__item-'+iconsGroup.id+'-'+i"
                        :class="{item_active: icon == value}"
                        v-show="activeGroupId === iconsGroup.id"
                        @click="$emit('input', icon)"
                        @keyup.enter.prevent="$emit('input', icon)"
                    >
                        <SvgIcon class="icon" :name="icon"/>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Select_icon_svg',
        props: {
            'segmentControlClass': {
                type: [String, Array, Object],
                default: () => ''
            },
            'segmentControlListClass': {
                type: [String, Array, Object],
                default: () => ''
            },
            'cardlistClass': {
                type: [String, Array, Object],
                default: () => ''
            },
            'iconsByGroups': Array,
            'mainList': {
                type: [Array, Object],
                default: () => []
            },
            'value': [Number, String],
        },
        data() {
            return {
                showMenu: false,
                activeGroupId: null,
                moreItem: {},
            }
        },
        computed: {
            _mainList() {
                return this.mainList.map(id => {
                    let group = this.iconsByGroups.find(g => { return g.id === id })
                    return {id, name: group.name || group.group}
                })
            },
            moreList() {
                return this.iconsByGroups
                    .filter(g => { return !this.mainList.includes(g.id) })
                    .map(g => { return {id: g.id, name: g.name || g.group} })
            },
        },
        methods: {
            onClickMoreList(group) {
                this.showMenu = false
                this.activeGroupId = group.id
                this.moreItem = group
            },
        },
        created() {
        },
        updated() {
        },
        mounted() {
            //console.log('Select_icon', this, this.$config.img_baseURL)
            let group = this.iconsByGroups.find(g => { return g })
            //if(this.mainList.length)
            {
                group = this.iconsByGroups.find(g => {
                    return g.icons.includes(this.value)
                })
            }

            this.activeGroupId = group.id

            if (this.moreList.length) {
                this.moreItem = (this.mainList.includes(group.id)) ?
                    this.moreList[0] : group
            }
        }
    }
</script>