<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
            <!-- <h3 class="form-subtitle mb__12">{{ $t('Unit.Unit icon') }}</h3> -->
            <Select_icon_svg
                class="mb__12"
                :segment-control-list-class="'mb__4'"
                :icons-by-groups="unit_icons_by_groups"
                :main-list="['dynamic', 'static']"
                v-model="unit_icon"
            >
            </Select_icon_svg>
            <FieldGroup_color_chooser
                :fieldGroup_line_class="'mb__12'"
                :style_param_name="'color'"
                v-if="isUnitIconColored"
                v-model="unit.icon_color"
            >
            <SvgIcon :name="unit_icon" class="icon svg-icon-unit-marker"/>

            </FieldGroup_color_chooser>
            <CheckboxControl
                    :control-class="'mb__40'"
                    :label="$t('Unit.Use Halo')"
                    v-model="unit.icon_halo"
            />

    </section>
</template>
<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import Select_icon_svg from "@/components/_base/Select_icon_svg.vue";

    export default {
        name: 'UnitEdit_Icon',
        props: [
            'value',
            'unitId',
            'errors',
        ],
        components: {
            Select_icon_svg,
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
                "getFilterSearchLists"
            ]),
            isUnitIconColored(){
                let icon = this.getFilterSearchLists.Units.car_icons.find(ic => { return ic.id == this.unit_icon })
                return icon.isColored
            },
            isNew(){
               return !(this.unitId && this.unitId>0)
            },
            _errors(){//hack on update
                return Object.keys(this.errors)
            },
            unit(){
                return this.value
            },
            unit_icons_by_groups(){
                let icons = this.getFilterSearchLists.Units.car_icons
                    .reduce((out, icon) => {
                        let id = icon.group.toLowerCase()
                        if(!out[id]) {
                            out[id] =
                                {
                                    id,
                                    name: this.$t('text.'+id),
                                    group: this.$t('text.'+id),
                                    isColored: icon.isColored,
                                    icons: [icon.id]
                                }
                        }else{
                            out[id].icons.push(icon.id)
                        }
                        return out
                    }, {})
                return Object.values(icons)
            },
            unit_icon: {
                get(){ return this.unit.icon || 'map__car_type_sedan' },
                set(icon){ this.unit.icon = icon }
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
        created() {
            // console.log('UnitEdit_Options created', this.unitId, {...this.unit})
        },
        updated() {
            // console.log('UnitEdit_Options updated', this.unitId, {...this.unit})
        },
        mounted() {
            // console.log('UnitEdit_Options mounted', this.unitId, {...this.unit})
        }
    }
</script>

<style scoped>

</style>